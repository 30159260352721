import bgImg from "./bgGIF.gif";
import logo from "./LogoSimple.png";
import Countdown from "react-countdown";
import "./App.css";

function App() {
  const Completionist = () => <span>You are good to go!</span>;
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {days} d / {hours} h / {minutes} m / {seconds} s
        </span>
      );
    }
  };
  return (
    <div class="page">
      <img src={bgImg} className="bgImg" alt="Background Image" />
      <img src={logo} className="logo" alt="logo" />
      <header>
        <h1>A Cloud Sync</h1>
      </header>
      <main>
        <span className="comingSoon">Coming Soon</span>
        <hr />
        <p className="counter" id="">
          <Countdown date={"2023-08-01T00:00:00"} renderer={renderer} />
        </p>
      </main>
      <footer>
        <a
          className="socials"
          href="https://www.facebook.com/acloudsync/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          className="socials"
          href="https://twitter.com/acloudsync/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          className="socials"
          href="https://www.linkedin.com/company/acloudsync"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkenIn
        </a>
        <a
          className="socials"
          href="https://www.youtube.com/@ACloudSync"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube
        </a>
        <a
          className="socials"
          href="https://www.fiverr.com/acloudsync/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fiverr
        </a>
        <a
          className="socials"
          href="https://www.instagram.com/acloudsync/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="socials"
          href="https://www.tiktok.com/acloudsync/"
          target="_blank"
          rel="noopener noreferrer"
        >
          TikTok
        </a>
      </footer>
    </div>
  );
}

export default App;
